@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
.Grid_Container{
    height: 100%;
    width: 100%;
    background: url("../../../images/Fond_accueil.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    /* padding-top: 15em; */
    /* padding-bottom: 15em; */
}
.commanderHeader {
    text-decoration: none;
    color: #FFFFFF;
}
.commanderHeader:hover {
    color: #FFFFFF;
}

.Div_Text_Header{
    height: 40%;
    font-size: 6.5em;
    /* font-family: "Teko"; */
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto;
    align-self: center;
}

#Btn_Commander_Accueil{
    font-size: var(--font-size-button);
    font-family: "Teko";
    background-color: #DCA10B;
    color: #FFFFFF;
    border-color: #DCA10B;
    border-radius: 20px;
    padding: 8px 40px;
   
}
#Btn_Commander_Accueil:hover {
    background-color: #f1cf0a;

}

/* responsive */
#header {
    height: 100vh;
    position: relative;
    z-index: 5;
}

/* for w < 960 or h < 730 */
@media screen and (max-width: 960px) , (max-height: 800px) {

    #Btn_Commander_Accueil{
        font-size: var(--font-size-button-mobile);
        margin-top: 5vh;
        padding: 8px 20px;  
    }
}
.image-logo{
    display: none;
}

/* -------------- WIDTH --------------------- */
/* w <= 1104 */

@media screen and (max-width: 1609px) {
    .Div_Text_Header {
       font-size: 5.5em;
       /* margin-top: -10vh; */
    }
}
@media screen and (max-width: 1380px) {
    .Div_Text_Header {
       font-size: 5em;
       /* margin-top: -10vh; */
    }
    #header{
        position: static;
    }
}
@media screen and (max-width: 1000px) {
    .Div_Text_Header {
       font-size: 4.5em;
       /* margin-top: -10vh; */
    }
}
/* @media screen and (max-width: 1104px) {
    .Div_Text_Header {
       font-size: 5em;
       margin-top: -10vh;
    }
} */
/* 746 <= w <= 1104 */
@media screen and (max-width: 865px) {
    .Div_Text_Header {
       font-size: 4em;
       /* margin-top: -10vh; */
    }
    .image-logo{
        display: flex;
        justify-content: center;
        align-self: center;
        margin-top: 10px;
    }
    #header{
        position: relative;
    }
    .Div_Text_Header{
        align-self: flex-start;
    }
    
}

@media screen and (max-width: 608px) {
    .Div_Text_Header {
       font-size: 3em;
       margin-top: -2vh;
    }
    .Grid_Container{
        background-position: center;
    }
}

@media screen and (max-width: 514px) {
    .Div_Text_Header {
       font-size: 2.5em;
       margin-top: -15vh;
       margin-bottom: 10vh;
       align-self: center;
    }
    #header{
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: flex-start; */
        position: relative;

    }
}
@media screen and (max-width: 350px) {
    .image-logo img{
        width: 80%;
    }
    #Btn_Commander_Accueil {
        font-size: 24px;
    }
    .Div_Text_Header {
        margin-top: -20vh
;
    }
}

/* -------------- MIN WIDTH 332 --------------------- */


/* -------------- HEIGHT -------------- */

@media only screen and (max-width: 514px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 596px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 515px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 790px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 645px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 893px) {
    .Div_Text_Header {
        font-size: 6em;
        margin-top: -40vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 741px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 626px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 529px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}




/* -------------- MIN HEIGHT 550 -------------- */

/* -------------- WIDTH AND HEIGHT  --------------------- */

/* -------------- MIN WIDTH AND HEIGT --------------------- */

