@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

.galeriePage {
  /* background-color: #3c3c3c; */
  width: 100%;
  /*background-image: url("../../images/bg.png");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: #464646 !important; */
  position: relative;
  z-index: 1;
}


.galerieTitle {
  color: white;
  text-align: center;
  font-size: 70px !important;
  font-family: "Teko"!important;
  font-weight: 700 !important;
  padding-top: 100px;
  line-height: 72px;
  letter-spacing: 15% !important;
  text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.cards__items_1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cards__items_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#img1 {
  margin-right: 45px;
}

#img2 {
  margin-left: 45px;
}








/* style for mobile */



/* style for image gallerie mobile*/


/* Responsive */
#galerie {
  height: max-content;
  position: relative;
  z-index: 2;
  margin-top: -102px;
}

.galerieContainer {
  padding-top: 20px;
  padding-bottom: 40px;
}



.cards {
  padding: 1rem;
  background: rgb(87, 43, 131);
}

h1 {
  text-align: center;
}



li {
  list-style-type: none;

}

.cards__items2 {
  display: flex;
  flex-basis: 100px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}


/* ------Responsive------ */



@media only screen and (max-width: 1600px) {
  .menu_img {
    height: 300px;
      width: 420px;
    }
    .cards__items_1 {
      align-items: center;
      justify-content: space-between;
      padding-left: 0 !important;
    }
    .cards__items_2 {
      padding-left: 0 !important;

    }
  }
  @media only screen and (max-width: 1300px) {
    .menu_img {
      height: 240px;
      width: 300px;
    }
    .cards__items_1 {
      align-items: center;
      justify-content: space-between;
      padding-left: 0 !important;
    }
    .cards__items_2 {
      padding-left: 0 !important;
      
    }
  }
  @media only screen and (max-width: 1000px) {
    .menu_img {
      height: 200px;
      width: 250px;
    }
    .cards__items_1 {
      align-items: center;
      justify-content: space-between;
      padding-left: 0 !important;
    }
    .cards__items_2 {
      padding-left: 0 !important;
      
    }
  }
  
  /* for w < 960 or h < 730 */
  @media screen and (max-width: 960px), (max-height: 800px) {
    .galerieTitle {
      font-size: var(--font-size-titre-moyenne);
    }
    
  }
  
  /* for w < 960 or h < 730 */
  @media screen and (max-width: 612px), (max-height: 600px) {
    .galerieTitle {
      font-size: var(--font-size-titre-petite) !important;
    }
  }
  @media only screen and (max-width: 800px) {
    .cards__items_1 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 0 !important;
    }
  
    .cards__items_2 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 0 !important;
    }
    .cards__items_1 > li{
      margin-bottom: 20px;
    }
    .cards__items_2 > li{
      margin-bottom: 20px;
    }
  
    .menu_img {
      height: 300px;
      width: 300px;
    }
  
    #img1 {
      margin-right: 0;
    }
  
    #img2 {
      margin-left: 0;
    }
    .cards{
      justify-content: center;
    }
  }