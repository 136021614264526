@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');

.myModal__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myModal__body__content{
  font-family: "Raleway"!important;
  font-weight: 500 !important;
}

.myModal__modal {
  max-height: 100vh;
  overflow-y: auto;
  max-width: 500px;
  width: 95%;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  position: relative;
}

.myModal__modal__close {
  position: absolute;
  top: 2px;
  right: 10px;
}
.myModal__modal__close i {
  font-size: 20px;
  pointer-events: none;
}

.myModal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.myModal__header h1 {
  text-align: center;
  color: rgb(100, 100, 100);
  font-size: 2.2rem;
  font-family: "Teko";
  font-weight: 700;
}
.myModal__header h2 {
  text-align: center;
  color: rgb(100, 100, 100);
  font-size: 1.5rem;
}
.myModal__header p {
  margin: 0;
}

.myModal__header img {
  max-width: 200px;
  max-height: 150px;
  object-fit: contain;
}

.myModal__body,
.myModal__footer {
  padding: 10px 0;
  border-bottom: 1.2px solid lightgray;
}

.myModal__body__heading {
  /* font-size: 1rem !important ; */
  font-family: "Raleway";
}

.myModal__footer {
  border: none;
  display: flex;
  justify-content: center;
}


@media (max-width: 600px) {
  .MuiButton-label {
    padding: 1rem;
    font-size: 1rem;
  }
  .MuiButton-label .MuiButton-endIcon{
    display: none !important;
  }
  .myModal__modal {
    max-height: 100vh;
    overflow-y: auto;
    max-width: 500px;
    width: 95%;
    height: 90%;
    padding: 1.5rem;
    background: white;
    border-radius: 10px;
    position: relative;
  }
  .myModal__footer {
    padding: 10px 0;
    border-bottom: 1.2px solid lightgray;
    max-height: 15%;
  }
}

@media (max-width: 500px) {
  .myModal__header img {
    max-width: 175px;
    max-height: 125px;
  }

  .myModal__header h1 {
    font-size: 1.8rem;
  }
  .myModal__header h2 {
    font-size: 1.3rem;
  }
  .myModal__modal {
    margin-top: 5%;
  }
}
