@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
.nousRetrouver_container{
    display: flex;
    flex-flow:nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 920px;
    background: url("../../../images/contact/backgroungpageinfo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.best_sellers{
    color: white;
    padding-top: 0 !important;
    font-family: "Teko";
    
}
.TITRE-container{
    margin-top: 20px;
    padding-top: 0 !important;
}
.img_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color:rgb(87, 43, 131) ;
    height: 80%;
    width: 80%;
    margin-top: 80px !important;
    margin-bottom: 150px !important;
}
.command_button{
    text-align: center;
    margin-bottom: 35px !important;
    font-family: "Teko";
  }
  .nousRetrouver_img{
      display: inline-flex;
      align-items: center;
      justify-content: center;
  }
  .image{
      width: 90%;
  }

  @media screen and (max-width: 500px) {

    .img_container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 100px !important;
        margin-bottom: 100px !important;
        height: 60%;

    }
    .image{
        width: 82%;
    }
    .nousRetrouver_container{
        background-position: left;
    }
    
}
/* @media screen and (max-width: 1300px) {
    .img_container{
        height: 40%;
        width: 90%;
        margin-bottom: 100px !important;
        margin-top: 100px !important;
    }
    .nousRetrouver_container{
        background-position: left;
    }
} */
